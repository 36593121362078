import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto kdf`}</strong>{` -- key derivation functions for password hashing and verification`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto kdf <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto kdf`}</strong>{` command group creates and verifies passwords using key
derivation functions.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Derive a password using `}<strong parentName="p">{`scrypt`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto kdf hash
Enter password to hash: 
$scrypt$ln=15,r=8,p=1$3TCG+xs8HWSIHonnqTp6Xg$UI8CYfz6koUaRMjDWEFgujIxM63fYnAcc0HhpUryFn8

$ step crypto kdf hash --insecure password
$scrypt$ln=15,r=8,p=1$U8Fl1sO6LWkFeXs5GQS0vA$Rj8nPeaBFQUzbU21N+hhm3I/s1WTxao7Dje4G6ZvO9Q
`}</code></pre>
    <p>{`Derive a password using `}<strong parentName="p">{`bcrypt`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto kdf hash --alg bcrypt
Enter password to hash: 
$2a$10$EgTYeokp/EhvlMpaDYX56O67M/Ve4JyTl9DHwailYYFOBT3COSTuy

$ step crypto kdf hash --alg bcrypt --insecure password
$2a$10$kgYs5dEKs2C6Y5PXnU7eTuPzHMeSoCnkvtTL7ghsPDdSSmw5ec/sS
`}</code></pre>
    <p>{`Derive a password using `}<strong parentName="p">{`argon2i`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto kdf hash --alg argon2i
Enter password to hash: 
$argon2i$v=19$m=32768,t=3,p=4$H0IxAhFFO7fOu5K2RYTxxA$ULEz/79vh3BtCcm7W/zRfJSpiEGULirrZ+PnHfspWKA
`}</code></pre>
    <p>{`Derive a password using `}<strong parentName="p">{`argon2id`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto kdf hash --alg argon2id
Enter password to hash: 
$argon2id$v=19$m=65536,t=1,p=4$HDi5gI15NwJrKveh2AAa9Q$30haKRwwUe5I4WfkPZPGmhJKTRTO+98x+sVnHhOHdK8
`}</code></pre>
    <p>{`Validate a hash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto kdf compare '$scrypt$ln=15,r=8,p=1$3TCG+xs8HWSIHonnqTp6Xg$UI8CYfz6koUaRMjDWEFgujIxM63fYnAcc0HhpUryFn8'
Enter password to compare: 
ok

$ step crypto kdf compare --insecure '$scrypt$ln=15,r=8,p=1$3TCG+xs8HWSIHonnqTp6Xg$UI8CYfz6koUaRMjDWEFgujIxM63fYnAcc0HhpUryFn8' password
ok

$ step crypto kdf compare '$2a$10$EgTYeokp/EhvlMpaDYX56O67M/Ve4JyTl9DHwailYYFOBT3COSTuy'
Enter password to compare: 
ok

$ step crypto kdf compare --insecure '$2a$10$EgTYeokp/EhvlMpaDYX56O67M/Ve4JyTl9DHwailYYFOBT3COSTuy' password
ok

$ step crypto kdf compare '$argon2i$v=19$m=32768,t=3,p=4$H0IxAhFFO7fOu5K2RYTxxA$ULEz/79vh3BtCcm7W/zRfJSpiEGULirrZ+PnHfspWKA'
Enter password to compare: 
ok

$ step crypto kdf compare --insecure '$argon2i$v=19$m=32768,t=3,p=4$H0IxAhFFO7fOu5K2RYTxxA$ULEz/79vh3BtCcm7W/zRfJSpiEGULirrZ+PnHfspWKA' password
ok

$ step crypto kdf compare --insecure '$argon2id$v=19$m=65536,t=1,p=4$HDi5gI15NwJrKveh2AAa9Q$30haKRwwUe5I4WfkPZPGmhJKTRTO+98x+sVnHhOHdK8'
Enter password to compare: 
ok

$ step crypto kdf compare --insecure '$argon2id$v=19$m=65536,t=1,p=4$HDi5gI15NwJrKveh2AAa9Q$30haKRwwUe5I4WfkPZPGmhJKTRTO+98x+sVnHhOHdK8' password
ok
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "hash/"
              }}>{`hash`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`derive a secret key from a secret value (e.g., a password)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "compare/"
              }}>{`compare`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`compare a plaintext value (e.g., a password) and a hash`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      